var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Sửa khoản công lương mặc định",
        visible: _vm.dialogVisible,
        width: "1150px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "160px",
            model: _vm.dataInput,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên khoản", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập tên khoản" },
                        model: {
                          value: _vm.dataInput.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "name", $$v)
                          },
                          expression: "dataInput.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại", prop: "category" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Loại" },
                          on: {
                            change: function ($event) {
                              return _vm.changeDiscountTypeMethod()
                            },
                          },
                          model: {
                            value: _vm.dataInput.category,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "category", $$v)
                            },
                            expression: "dataInput.category",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Chi", value: "out" },
                          }),
                          _c("el-option", {
                            attrs: { label: "Thu", value: "in" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 1,
                  type: "textarea",
                  autosize: "",
                  placeholder: "Nhập mô tả",
                },
                model: {
                  value: _vm.dataInput.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "description", $$v)
                  },
                  expression: "dataInput.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đơn vị tính", prop: "unit" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập đơn vị tính" },
                        model: {
                          value: _vm.dataInput.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "unit", $$v)
                          },
                          expression: "dataInput.unit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số lượng", prop: "number" } },
                    [
                      _c("el-input-number", {
                        attrs: { precision: 0, min: 1 },
                        model: {
                          value: _vm.dataInput.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "number", $$v)
                          },
                          expression: "dataInput.number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đơn giá", prop: "price" } },
                    [
                      _c("el-currency-input", {
                        model: {
                          value: _vm.dataInput.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "price", $$v)
                          },
                          expression: "dataInput.price",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Giảm giá", prop: "discount" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataInput.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "discount", $$v)
                            },
                            expression: "dataInput.discount",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataInput.discount
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Loại giảm giá",
                            prop: "discountType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Loại" },
                              on: {
                                change: function ($event) {
                                  return _vm.changeDiscountTypeMethod()
                                },
                              },
                              model: {
                                value: _vm.dataInput.discountType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataInput, "discountType", $$v)
                                },
                                expression: "dataInput.discountType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Phần trăm", value: "percent" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Tiền", value: "money" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataInput.discount
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.dataInput.discountType == "percent"
                                ? "Phần trăm giảm"
                                : "Tiền giảm",
                            prop: "discountNumber",
                          },
                        },
                        [
                          _vm.dataInput.discountType == "percent"
                            ? _c("el-input-number", {
                                attrs: { precision: 0, min: 0, max: 100 },
                                model: {
                                  value: _vm.dataInput.discountNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataInput,
                                      "discountNumber",
                                      $$v
                                    )
                                  },
                                  expression: "dataInput.discountNumber",
                                },
                              })
                            : _vm.dataInput.discountType == "money"
                            ? _c("el-currency-input", {
                                model: {
                                  value: _vm.dataInput.discountNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataInput,
                                      "discountNumber",
                                      $$v
                                    )
                                  },
                                  expression: "dataInput.discountNumber",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.dataInput.discount
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Đơn giá khi giảm",
                            prop: "discountPrice",
                          },
                        },
                        [
                          _c("currency-input", {
                            staticStyle: {
                              padding: "10px",
                              width: "98%",
                              "text-align": "right",
                              background: "#e7e9ec",
                              border: "1px solid #dcdfe6",
                              "font-weight": "bold",
                            },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.dataInput.discountPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataInput, "discountPrice", $$v)
                              },
                              expression: "dataInput.discountPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tự động đăng ký", prop: "active" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataInput.active,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "active", $$v)
                            },
                            expression: "dataInput.active",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tháng áp dụng" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.checkAll,
                            callback: function ($$v) {
                              _vm.checkAll = $$v
                            },
                            expression: "checkAll",
                          },
                        },
                        [_vm._v("Tất cả")]
                      ),
                      _c("el-checkbox", {
                        attrs: { label: "T1" },
                        model: {
                          value: _vm.dataInput.t1,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t1", $$v)
                          },
                          expression: "dataInput.t1",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T2" },
                        model: {
                          value: _vm.dataInput.t2,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t2", $$v)
                          },
                          expression: "dataInput.t2",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T3" },
                        model: {
                          value: _vm.dataInput.t3,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t3", $$v)
                          },
                          expression: "dataInput.t3",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T4" },
                        model: {
                          value: _vm.dataInput.t4,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t4", $$v)
                          },
                          expression: "dataInput.t4",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T5" },
                        model: {
                          value: _vm.dataInput.t5,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t5", $$v)
                          },
                          expression: "dataInput.t5",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T6" },
                        model: {
                          value: _vm.dataInput.t6,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t6", $$v)
                          },
                          expression: "dataInput.t6",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T7" },
                        model: {
                          value: _vm.dataInput.t7,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t7", $$v)
                          },
                          expression: "dataInput.t7",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T8" },
                        model: {
                          value: _vm.dataInput.t8,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t8", $$v)
                          },
                          expression: "dataInput.t8",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T9" },
                        model: {
                          value: _vm.dataInput.t9,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t9", $$v)
                          },
                          expression: "dataInput.t9",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T10" },
                        model: {
                          value: _vm.dataInput.t10,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t10", $$v)
                          },
                          expression: "dataInput.t10",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T11" },
                        model: {
                          value: _vm.dataInput.t11,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t11", $$v)
                          },
                          expression: "dataInput.t11",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "T12" },
                        model: {
                          value: _vm.dataInput.t12,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "t12", $$v)
                          },
                          expression: "dataInput.t12",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Áp dụng điểm danh", prop: "attendance" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataInput.attendance,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "attendance", $$v)
                            },
                            expression: "dataInput.attendance",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataInput.attendance
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Loại điểm danh",
                            prop: "attendanceType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Chọn loại điểm danh" },
                              on: {
                                change: function ($event) {
                                  return _vm.changeAttendanceTypeMethod()
                                },
                              },
                              model: {
                                value: _vm.dataInput.attendanceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataInput, "attendanceType", $$v)
                                },
                                expression: "dataInput.attendanceType",
                              },
                            },
                            _vm._l(_vm.attendanceTypeList, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { value: item.key, label: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataInput.attendance
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Chi tiết",
                            prop: "attendanceDetail",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Chọn chi tiết" },
                              model: {
                                value: _vm.dataInput.attendanceDetail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataInput,
                                    "attendanceDetail",
                                    $$v
                                  )
                                },
                                expression: "dataInput.attendanceDetail",
                              },
                            },
                            _vm._l(_vm.attendanceDetailList, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { value: item.key, label: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }